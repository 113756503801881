import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import { EncryptStorage } from "encrypt-storage"
import * as uuid from "uuid"
import Cookie from "js-cookie"

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleEvent from './event/moduleEvent.js'
import moduleSensor from './sensor/moduleSensor.js'
import moduleThreshold from './threshold/moduleThreshold.js'
import moduleSurvey from './surveys/moduleSurveys.js'
import moduleSurveyResponses from './surveyresponses/moduleSurveyResponses.js'
import moduleSurveyHubs from './surveyhubs/moduleSurveyHubs.js'
import moduleUsers from './users/moduleUsers.js'
import moduleAnalytics from './analytics/moduleAnalytics.js'
import moduleBenchmark from "@/js/store/benchmark/moduleBenchmark";
import moduleAccounts from "@/js/store/accounts/moduleAccounts";

let cookieName = 'cloudKey'
let cookieValue = Cookie.get(cookieName)
let encryptionToken = (!cookieValue)?uuid.v4():cookieValue.split('|')[0]
let ls = new EncryptStorage(encryptionToken)

// Store the encryption token in a secure  cookie.
let secure = process.env.NODE_ENV != 'development'
if (!cookieValue) {
  Cookie.set(cookieName, encryptionToken + '|0', { secure: secure })
} else {
  if (cookieValue.split('|')[1] == 1) {
    Cookie.set(cookieName, encryptionToken + '|1', { secure: secure, expires: 30 })
  }
}
const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: {
    getItem: (key) => {
      try {
        return ls.getItem(key)
      } catch (e) {
        return null
      }
    },
    setItem: (key, value) => {
      ls.setItem(key, value)
    },
    removeItem: (key) => {
      ls.removeItem(key)
    }
  },

  reducer: state => ({
    package_version: state.package_version,
    auth:{
      user: state.auth.user,
      accessToken: state.auth.accessToken,
      tokenExpiry: state.auth.tokenExpiry,
    },
    threshold:{
      threshold_data_cache: state.threshold.threshold_data_cache,
    },
    muted_zones: state.muted_zones,
    notification_frequency: state.notification_frequency,
    post_login_page: state.post_login_page,
    analytics: {
      event_list: state.analytics.event_list,
      last_event: state.analytics.last_event
    }
  })
});

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    auth: moduleAuth,
    event: moduleEvent,
    benchmark: moduleBenchmark,
    sensors: moduleSensor,
    threshold: moduleThreshold,
    surveys: moduleSurvey,
    surveyhubs: moduleSurveyHubs,
    surveyresponses: moduleSurveyResponses,
    users: moduleUsers,
    analytics: moduleAnalytics,
    accounts: moduleAccounts,
  },
  plugins: [vuexLocalStorage.plugin]
})


import axios from '@/js/axios.js'

export default {
  idleTime({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/admin/analytics/idle-time', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  interimReports({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: '/admin/analytics/' + payload.event_uuid + '/interim-reports',
        method: 'POST',
        responseType: 'blob', // important
        data: payload
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error)
      });
    })
  },
  events({commit}, newly_started = false) {
    return new Promise((resolve, reject) => {
      axios.get(
        '/spatial/reporting/events/?db_ids=1' + (newly_started ? '&newly_started=1' : '')
      ).then((response) => {
        commit('SET_EVENT_LIST', response.data)
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  headlineReport({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/spatial/reporting/account-headlines', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  reportDetail({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/spatial/reporting/report-detail', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  reportConfig({commit}, payload) {
    return new Promise((resolve, reject) => {
      if (typeof payload == 'number') {
        axios.get('/spatial/reporting/report-config/' + payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        axios.post('/spatial/reporting/report-config', payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
  uploadHeatmap({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/spatial/reporting/heatmap',
        payload,
        {headers: {'Content-Type': 'multipart/form-data'}}
      ).then((response) => {
        resolve(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
  clearHeatmap({commit}, event_id) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/spatial/reporting/heatmap',
        {event_id: event_id}
      ).then((response) => {
        resolve(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateHeatmapPositons({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/spatial/reporting/heatmap-zones',
        payload
      ).then((response) => {
        resolve(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
  eventExtras({commit}, payload) {
    return new Promise((resolve, reject) => {
      if (typeof payload == 'number') {
        axios.get('/spatial/reporting/event-extras/' + payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        axios.post('/spatial/reporting/event-extras', payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  },
}
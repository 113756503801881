export default {
    users:[],
    account_logo: null,
    event_list: null,
    last_event: null,
    dashboard_sections:[
        {
            id: 'summary',
            label: 'Event Summary',
            icons: [{icon: 'laptop'}, {icon: 'graph-up', scale: 0.4}],
            options: [
                {id: 'funnel', name: 'OTS Funnel'},
                {id: 'uniquesummary', name: 'Uniques'},
                {id: 'peaktimes', name: 'Peak Times'},
                {id: 'dwellsummary', name: 'Dwell Time'},
                {id: 'repeatsummary', name: 'Visits and Repeats'}
            ]
        },
        {
            id: 'visits',
            label: 'Unique Visits and Visitors',
            icons: [{icon: 'people-fill'}],
            options: [
                {id: 'bydaygraph', name: 'Passerby/Engaged by Day Graph'},
                {id: 'engmap', name: 'Engaged by Hour'},
                {id: 'byzonegraph', name: 'Passerby/Engaged by Zone Graph'}
            ]
        },
        {
            id: 'dwell',
            label: 'Dwell Time',
            icons: [{icon: 'pie-chart-fill'}],
            options: [
                {id: 'dwellzone', name: 'Dwell by Zone'},
                {id: 'dtdzoneg', name: 'Distributed Dwell by Zone Graph'},
                {id: 'dtdzoneb', name: 'Distributed Dwell by Zone Bars'},
                {id: 'dwellsite', name: 'On Site Dwell'},
                {id: 'dtdsitep', name: 'Distributed On Site Dwell Pie'},
                {id: 'dtdsiteb', name: 'Distributed On Site Dwell Bars'},
            ]
        },
        {
            id: 'repeat',
            label: 'Repeat Visits',
            icons: [{icon: 'arrow-repeat'}],
            options: [
                {id: 'repeatvisits', name: 'Repeat Visits (Engaged)'},
                {id: 'repeatintraday', name: 'Repeat Visitors (Same Day)'},
                {id: 'repeatinterday', name: 'Repeat Visitors (Multi-Day)'},
                {id: 'daysvisited', name: 'Days Visited'},
        ]},
        {
            id: 'heatmap',
            label: 'Density Map',
            icons: [{icon: 'map'}],
            options: [
                {id: 'heatmap', name: 'Density Map'}
            ]
        },
        {
            id: 'glossary',
            label: 'Glossary',
            icons: [{icon: 'book'}],
            options: [
                {id: 'glossary', name: 'Glossary'}
            ]
        }
    ]
}
<template>
  <div
    id="app"
    class="w-full"
  >
    <header
      id="header"
      class="no-print"
    >
      <b-container>
        <b-row>
          <b-col>
            <b-navbar
              toggleable="lg"
              class="shadow bg-white"
            >
              <b-button
                v-if="isAuthenticated"
                v-b-toggle.sidebar
                variant="transparent"
                class="mr-1"
              >
                <span class="navbar-toggler-icon" />
              </b-button>

              <b-navbar-brand>
                <img
                  alt="Meshh"
                  src="./assets/meshh-ll-logo.svg"
                >
              </b-navbar-brand>

              <div
                v-if="isAuthenticated"
                class="nb-right ml-auto"
                @click="show = !show"
              >
                <div class="user_greet">
                  <span
                    v-if="$router.currentRoute.name && $router.currentRoute.name.match(/report.dashboard/) && $store.state.analytics.account_logo"
                    class="mr-2"
                  >
                    <img :src="base_url+'media/' + $store.state.analytics.account_logo">
                  </span>
                  <span
                    v-else-if="$store.state.auth.user.account_logo"
                    class="mr-2"
                  >
                    <img :src="base_url+'media/' + $store.state.auth.user.account_logo">
                  </span>
                  <span>
                    <b-dropdown
                      right
                      block
                      menu-class="w-100"
                      variant="outline-primary"
                    >
                      <template #button-content>
                        <span
                          v-if="$store.state.auth.user.name"
                          style="float: left"
                        >{{ $store.state.auth.user.name }}&nbsp;</span>
                        <b-iconstack id="user-icon">
                          <b-icon-circle-fill
                            stacked
                            variant="white"
                          />
                          <b-icon-person-circle
                            stacked
                            variant="primary"
                          />
                        </b-iconstack>
                      </template>
                      <b-dropdown-item :to="{name:'users.profile'}">
                        Profile
                      </b-dropdown-item>
                      <b-dropdown-item @click="logout">
                        Logout
                      </b-dropdown-item>
                      <b-dropdown-divider />
                      <b-dropdown-item>
                        Version: {{ $store.state.package_version }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <!--b-tooltip :show.sync="show" target="tooltip-icon" placement="bottom" variant="secondary">
                                            <b-row>
                                                <b-col>
                                                    <span class="d-inline mr-3 text-lowercase" v-if="$store.state.auth.user.name">
                                                        {{ $store.state.auth.user.name }}
                                                    </span>
                                                </b-col>
                                                <b-col>
                                                    <b-button variant="primary" @click="logout">Profile</b-button>
                                                </b-col>
                                            </b-row>
                                            <b-col>&nbsp;</b-col>
                                            <b-col>
                                                <b-button variant="primary" @click="logout">Logout</b-button>
                                            </b-col>
                                        </b-tooltip-->
                  </span>
                </div>
              </div>
            </b-navbar>
          </b-col>
        </b-row>
      </b-container>
    </header>
    <div
      id="topbox"
      ref="topbox"
    />
    <main id="main">
      <b-container>
        <b-row class="no-print">
          <b-col>
            <breadcrumbs @show-category="showSidebar" />
          </b-col>
        </b-row>
        <b-overlay
          :show="loading || $store.state.idleVue.isIdle"
          no-wrap
          fixed
          z-index="2000"
        />
        <b-row>
          <b-col>
            <vue-page-transition name="zoom">
              <router-view @show-category="showSidebar" />
            </vue-page-transition>
          </b-col>
        </b-row>
        <template
          v-if="$store.state.idleVue.isIdle"
          #overlay
        >
          <idle-expire />
        </template>
      </b-container>
    </main>

    <side-nav
      v-if="isAuthenticated"
      ref="sidebar"
    />
  </div>
</template>

<script>

import SideNav from "@/js/components/sidenav/SideNav"
import Breadcrumbs from "@/js/components/breadcrumbs/BreadCrumbs"
import passport from '@/js/http/requests/auth/passport/index.js'
import {mapGetters} from "vuex";
import IdleExpire from "@/js/components/IdleExpire";

export default {
    name: 'App',
    components: {
    IdleExpire,
    SideNav,
    Breadcrumbs
},
    data() {
        return {
            notify_id: null,
            show: false,
            loading: false,
            loading_ignore: false,
            base_url: process.env.VUE_APP_BASEURL
        }
    },
    computed: mapGetters({
        isAuthenticated: "auth/isAuthenticated"
    }),
    mounted: function () {
        this.$root.$on('axios_request', () => {
            if (!this.loading_ignore) {
                this.loading = true;
            }
            this.loading_ignore = false
        });
        this.$root.$on('axios_request_complete', () => {
            this.loading = false;
        });
        this.$root.$on('silent_axios', () => {
            this.loading_ignore = true
        })
        this.$root.$on('userFeedback', data => {
            this.$bvToast.toast(data.message, {
                title: data.success ? 'Success' : 'Error',
                variant: data.success ? 'success' : 'danger',
                solid: true
            })
        })
        this.$root.$on('userLogout', data => {
            this.logout()
        });

    },
    async created() {

        // IF THE VERSION NUMBER FOR THE APP HAS CHANGED DELETE THIS USER
        if(this.$store.getters.appVersion != process.env.PACKAGE_VERSION){
            this.$store.state.package_version = process.env.PACKAGE_VERSION;
            //this.$store.commit('auth/DELETE_USER');
            this.$store.commit('clearState')
            localStorage.removeItem('analyticsEventList')
            if (this.$router.history.pending.name != 'reset.password') {
                this.$router.push({name: "login"}).catch(() => {});
            }
        }

        passport.init(this);
    },
    methods: {
        logout() {
            this.$store.dispatch('auth/logoutPassport')
                .then((response) => {
                    this.$acl.change('user');
                    this.$store.commit('clearState')
                    this.$router.push({name: "login", params: {to: this.$router.currentRoute.path}}).catch(() => {});
                    this.show = false;
                })
                .catch(error => {
                    console.dir(error.message)
                    this.$store.commit('clearState')
                })
        },
        showSidebar(category) {
            var sb = this.$refs.sidebar
            sb.showCategory(category)
        }
    }

}

</script>

<style lang="scss">

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: $meshh_dark;
    //height: 100vh;
}

@media print {
    #main {
        top: 0;
    }
    .card {
        border: none !important;
    }
}

.navbar-brand {
    width: 25%;
    margin: 0 !important;
    padding-bottom: 0 !important;
    img {
        height: 2.3rem;
    }
}

.navbar-toggler {
    padding: .1rem .5rem !important;
}

.nb-right {
    max-width: 50%;
}

.user_greet {
    display: table;
    span {
        display: table-cell;
        vertical-align: middle;
        padding-left: 0.5rem;
    }
    img {
        max-height: 2.3rem;
        max-width: 8rem;
    }
}


</style>

import axios from '@/js/axios.js'

export default {
  index ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      /*axios.post('/sensors/index', payload)
        .then((response) => {
          commit('SET_ZONES', response.data.zones)
          resolve(response)
        })
        .catch((error) => { reject(error) })*/
        payload.sortDesc = payload.sortDesc ? 1 : 0
        payload.selected = payload.selected ? 1 : 0
        payload.include_inactive = payload.include_inactive ?? 0
        axios.get(
            `/sensors/?`
            + (payload.account ? `account=${payload.account}&` : '')
            + (payload.search ? `search=${payload.search}&` : '')
            + (payload.sort ? `sort=${payload.sort}&` : '')
            + `sortDesc=${payload.sortDesc}&`
            + `results_per_page=${payload.results_per_page}&`
            + `page=${payload.page}&`
            + `include_inactive=${payload.include_inactive}&`
        ).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
  },
  add ({commit}, payload) {
    return new Promise((resolve, reject) => {
        axios.post('/sensors', payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
  },
  move({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/sensors/move', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  activate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/sensors/activate', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  rename({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/sensors/rename', {sensors: payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }

}

import state from './moduleAnalyticsState.js'
import mutations from './moduleAnalyticsMutations.js'
import actions from './moduleAnalyticsActions.js'
import getters from './moduleAnalyticsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

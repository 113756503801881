const actions = {
    setZones ({ commit, state }, zones) {

        let zones_down = false;
        let threshold_exceeded = false;

        for(let index in zones) {

            let zone = zones[index];

            let muted = state.muted_zones.indexOf(zone.id);
            if (muted < 0) {
                if (zone.is_up == 0) zones_down = true;
                if (zone.threshold == 100) threshold_exceeded = true;
            }

        }

        if(zones_down) this.$notify.noteZonesDown();
        if(threshold_exceeded) this.$notify.noteZonesThresholdExceeded();

        commit('setZones', zones);

    }
}

export default actions
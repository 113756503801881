import axios from '@/js/axios.js'

export default {
  index({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/surveyhubs', payload)
        .then((response) => {
          commit('SET_SURVEY_HUBS', response.data.survey_hubs)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveyhubs/create`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  show({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveyhubs/${payload.survey_hub}/show`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  edit({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveyhubs/${payload.survey_hub}/edit`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  docreate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveyhubs/create`, payload.form_data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveyhubs/${payload.survey_hub}/update`, payload.form_data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/surveyhubs/${payload.survey_hub}/delete`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
<template>
  <b-modal
    id="threshold"
    v-model="modal_show"
    size="lg"
    class="bg-indigo"
    header-text-variant="white"
    header-close-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @hidden="resetModal"
    @shown="modalShown"
  >
    <template #modal-title>
      <span class="modal-stub">Event:</span> {{ (selected_event) ? selected_event.name : '' }} <br>
      <span class="modal-stub">Zone:</span> {{ (selected_zone) ? selected_zone.name : '' }}
    </template>

    <b-alert
      v-model="show_errors"
      variant="danger"
      class="sm"
    >
      There is no data for this day
    </b-alert>

    <threshold-chart
      ref="ThresholdChart"
      :chart-data="threshold_data"
    />

    <div class="container p-0 mt-4">
      <b-input-group>
        <b-form-datepicker
          v-model="selected_date"
          :min="min"
          :max="max"
          hide-header
          variant="dark"
          @input="fetchThreshold"
        />

        <b-input-group-append
          v-if="isStaffMember"
        >
          <download-csv
            :data="csv_data"
            :name="(selected_event)? selected_event.name.replace(/\W/g, '') + '-threshold.csv' : 'event_data.csv'"
          >
            <b-button>
              <b-icon
                icon="cloud-download"
                font-scale="1.4"
                variant="white"
              />
            </b-button>
          </download-csv>
        </b-input-group-append>
      </b-input-group>

      <b-button-group class="w-100 mt-2">
        <b-button
          class="w-25"
          :disabled="(zoneIndex(selected_zone) > 0)? false: true"
          @click="prevZone"
        >
          <b-icon
            icon="play-fill"
            flip-h
            class="m-0"
          />
        </b-button>
        <zones-drop-down class="w-50" />
        <b-button
          class="w-25"
          :disabled="(zoneIndex(selected_zone) < (zones.length - 1))? false: true"
          @click="nextZone"
        >
          <b-icon
            icon="play-fill"
            class="m-0"
          />
        </b-button>
      </b-button-group>
    </div>

    <div class="loading-spinner position-absolute">
      <b-spinner
        v-if="loading_data"
        style="width: 3rem; height: 3rem;"
        variant="white"
      />
    </div>
  </b-modal>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import JsonCSV from 'vue-json-csv'
import ZonesDropDown from '../../views/zones/ZonesDropDown';

export default {
  name: 'ThresholdModal',
  components: {
    'zones-drop-down': ZonesDropDown,
    'download-csv': JsonCSV
  },
  data() {
    return {
      modal_show: false,
      threshold_data: {},
      csv_data: [],
      loading_data: false,
      show_errors: false,
      selected_date: null,
      min: new Date(),
      max: new Date(),
      current_threshold: 0
    }
  },
  computed: {
    ...mapGetters({
      hasThresholdData: 'threshold/hasThresholdData',
      isStaffMember: 'auth/isStaffMember',
      zoneIndex: 'zones/zoneIndex',
    }),
    ...mapState({
      threshold_data_cache: state => state.threshold.threshold_data_cache,
      zones: state => state.zones.zones,
      selected_event: state => state.event.selected_event,
      selected_zone: state => state.zones.selected_zone,
    }),
  },
  watch: {
    selected_zone: function (data) {
      if (this.selected_zone) {
        this.selected_date = (this.selected_date) ? this.selected_date : this.selected_event.event_start;
        this.fetchThreshold();
      }
    }
  },
  methods: {

    fetchThreshold() {

      let payload = {zone_id: this.selected_zone.id, selected_date: this.selected_date};

      if (this.hasThresholdData(payload)) {
        this.loadThresholdData(this.threshold_data_cache[this.selected_zone.id][this.selected_date]);
      } else {

        let comp = this;

        // limit the dates that a user may select from the calendar
        this.min = this.selected_event.event_start;
        this.max = this.selected_event.event_end;

        this.loading_data = true;

        this.$store.dispatch('threshold/fetch', payload)
            .then(function (response) {
              comp.loadThresholdData(response.data.threshold);
            })
            .catch(function (error) {

              comp.loading_data = false;
              comp.connectionFail();
            });

      }

    },
    loadThresholdData(threshold_data) {

      let data_count = Object.keys(threshold_data).length;
      let comp = this;
      this.show_errors = (data_count == 0) ? true : false;
      this.loading_data = false;
      this.modal_show = true;

      let data = {
        datasets: [{
          label: '% of limit',
          yAxisID: 'Percentage',
          data: Array.from(threshold_data, threshold => threshold.threshold_percentage),
          pointRadius: (data_count == 1) ? 3 : 0,
          borderColor: '#17a2b8',
          pointBackgroundColor: '#17a2b8'
        }, {
          label: 'Limit',
          yAxisID: 'Percentage',
          data: Array.from(threshold_data, threshold => threshold.threshold_notification),
          pointRadius: (data_count == 1) ? 0 : 0,
          borderColor: '#dd422a',
          pointBackgroundColor: '#dd422a'
        }],
        labels: Array.from(threshold_data, threshold => this.getRoundedTime(threshold.time))
      };

      if (this.isStaffMember) {
        data.datasets.push({
          label: 'Devices',
          yAxisID: 'Devices',
          data: Array.from(threshold_data, threshold => threshold.devices),
          pointRadius: (data_count == 1) ? 3 : 0,
          borderColor: '#494d52',
          pointBackgroundColor: '#494d52'
        });
      }

      //console.dir( this.$refs.ThresholdChart);
      //this.$refs.ThresholdChart.chartData = data;

      this.threshold_data = data;

      let csv_data = JSON.parse(JSON.stringify(threshold_data));
      csv_data.forEach(function (v) {
        delete v.threshold_notification
      });
      csv_data.forEach(function (v) {
        v.time = comp.getRoundedTime(v.time)
      });
      this.csv_data = csv_data;

    },
    getRoundedTime(time_queried) {

      let d = new Date(time_queried.replace(/-/g, '/') + ' UTC');
      let mins = d.getUTCMinutes();
      mins = Math.round(mins / 5) * 5;
      mins = ('0' + mins).slice(-2);
      return (process.env.VUE_APP_MODE == 'debug') ? d.toUTCString() : d.getUTCHours() + ':' + mins;

    },
    prevZone() {
      let index = this.zones.indexOf(this.selected_zone);
      this.$store.state.zones.selected_zone = this.zones[index - 1];
    },
    nextZone() {
      let index = this.zones.indexOf(this.selected_zone);
      this.$store.state.zones.selected_zone = this.zones[index + 1];
    },
    resetModal() {
      this.$store.state.zones.selected_zone = null;
      this.selected_date = null;
    },
    modalShown() {

    },
    downloadCSV() {

    },
    destroy() {
      this.$destroy();
    },
    connectionFail() {
      this.destroy();
      this.$router.push({name: 'ConnectionFailed'}).catch(() => {
      });
    },
    doPoll() {

    },
  }
}
</script>
<style lang="scss">

#threshold {
  .modal-content {
    background-color: $indigo !important;

    /*
    max-width: 100%;
    width:95vw;
    margin: 0;
    top: 2.5vh;
    bottom: 0;
    left: 2.5vw;
    right: 2.5vw;
    height: 95vh;
    display: flex;
    position: fixed;
    z-index: 100000;

     */

    .loading-spinner {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .modal-stub {
    color: var(--info);
  }

  .curr_date {
    vertical-align: middle;
  }
}

</style>

import Vue from 'vue'
import VueRouter from "vue-router";
import store from './store/store';

Vue.use(VueRouter)


const router = new VueRouter({

  routes: [
    {
      path: '/',
      redirect: { name: 'report.dashboard' },
      //component: () => import('@/js/views/Home.vue'),
      name: 'home',
      meta: {
        display_name: 'Home',
        slug: 'home',
        rule: 'client_user',
        authRequired: true,
        visible: false
      }
    },
    {
      path: '/login/:to?',
      component: () => import('@/js/views/login/Login.vue'),
      name: 'login',
      meta: {
        display_name: 'Login',
        slug: 'login',
        rule: 'user',
        visible: false
      }
    },
    {
        path: '/saml/callback/:response?',
        component: () => import('@/js/views/login/SamlCallback.vue'),
        name: 'saml.callback',
        meta: {
          display_name: 'SAML Callback',
          slug: 'saml.callback',
          rule: 'user',
          visible: false
        }
    },
    {
      path: '/reset/:token/:email',
      component: () => import('@/js/views/login/PasswordReset.vue'),
      name: 'reset.password',
      meta: {
        display_name: 'Password Reset',
        slug: 'reset.password',
        rule: 'user',
        visible: false,
        authRequired: false,
      }
    },
    {
      path: '/forgot',
      component: () => import('@/js/views/login/ForgotPassword.vue'),
      name: 'forgot.password',
      meta: {
        display_name: 'Forgot Password',
        slug: 'forgot.password',
        rule: 'user',
        visible: false,
        authRequired: false,
      }
    },
    {
      path: '/surveys',
      component: { render: h => h('router-view') },
      meta: {
        display_name: 'Research',
        rule: 'client_administrator',
        acl:['allow_all'],
        slug: 'research',
        authRequired: true,
        visible: false,
        icon: 'clipboard-data',
        open: false
      },
      children:[
        // Surveys
        {
          path: '/surveys/list',
          component: { render: h => h('router-view') },
          meta: {
            rule: 'client_administrator',
            display_name: 'Surveys',
            acl:['allow_all'],
            slug: 'surveys',
            authRequired: true,
            visible: true,
            breadcrumbs: [
              {display_name: 'Home', category: 'analytics'},
              {display_name: 'Surveys', category: 'surveys'},
            ],
          },
          children:[
            {
              path: '/surveys/list',
              component: () => import('@/js/views/surveys/Surveys.vue'),
              name: 'surveys.index',
              meta: {
                rule: 'client_administrator',
                acl:'surveys.index',
                slug: 'surveys.index',
                display_name: 'All Surveys',
                authRequired: true,
                visible: true,
                breadcrumbs: [
                  {display_name: 'Home', category: 'analytics'},
                  {display_name: 'Surveys', category: 'surveys'},
                ],
              },
            },
            {
              path: '/surveys/create',
              component: () => import('@/js/views/surveys/SurveyCreate.vue'),
              name: 'surveys.create',
              meta: {
                rule: 'client_administrator',
                acl:'surveys.create',
                slug: 'surveys.create',
                display_name: 'Create a Survey',
                authRequired: true,
                visible: true,
                breadcrumbs: [
                  {display_name: 'Home', category: 'analytics'},
                  {display_name: 'Surveys', category: 'surveys'},
                  { display_name: 'Surveys', name: 'surveys.index' },
                ],
              }
            },
          ]
        },
        {
          path: '/surveys/:survey/edit',
          component: () => import('@/js/views/surveys/SurveyEdit.vue'),
          name: 'surveys.edit',
          meta: {
            rule: 'client_administrator',
            slug: 'surveys.edit',
            acl:'surveys.edit',
            display_name: 'Edit a Survey',
            authRequired: true,
            visible: false,
            breadcrumbs: [
              {display_name: 'Home', category: 'analytics'},
              {display_name: 'Surveys', category: 'surveys'},
              { display_name: 'Surveys', name: 'surveys.index' },
            ],
          }
        },
        {
          path: '/surveys/:survey/responses',
          component: () => import('@/js/views/surveys/SurveyResponses.vue'),
          name: 'surveys.responses.review',
          meta: {
            rule: 'client_administrator',
            slug: 'surveys.responses.review',
            acl:'surveys.responses.review',
            display_name: 'Review a survey',
            authRequired: true,
            visible: false,
            breadcrumbs: [
              {display_name: 'Home', category: 'analytics'},
              {display_name: 'Surveys', category: 'surveys'},
              { display_name: 'Surveys', name: 'surveys.index' },
            ],
          }
        },
        {
          path: '/surveys/:survey/download',
          component: () => import('@/js/views/surveys/Surveys.vue'),
          name: 'surveys.responses.download',
          meta: {
            rule: 'client_administrator',
            slug: 'surveys.edit',
            acl:'surveys.responses.download',
            display_name: 'Download survey responses',
            authRequired: true,
            visible: false,
            breadcrumbs: [
              {display_name: 'Home', category: 'analytics'},
              {display_name: 'Surveys', category: 'surveys'},
              { display_name: 'Surveys', name: 'surveys.index' },
            ],
          }
        },
        // Survey Hubs
        {
          path: '/surveys/hubs',
          component: { render: h => h('router-view') },
          meta: {
            rule: 'client_administrator',
            slug: 'surveys.hubs',
            acl:['surveys.hubs.index', 'surveys.hubs.create'],
            display_name: 'Survey Hubs',
            authRequired: true,
            visible: true,
            breadcrumbs: [
              {display_name: 'Home', category: 'analytics'},
              {display_name: 'Surveys', category: 'surveys'},
            ],
          },
          children:[
            {
              path: '/surveys/hubs',
              component: () => import('@/js/views/surveyhubs/SurveyHubs.vue'),
              name: 'surveys.hubs.index',
              meta: {
                rule: 'client_administrator',
                slug: 'surveys.hubs.index',
                acl: 'surveys.hubs.index',
                display_name: 'All Survey Hubs',
                authRequired: true,
                visible: true,
                breadcrumbs: [
                  {display_name: 'Home', category: 'analytics'},
                  {display_name: 'Surveys', category: 'surveys'},
                ],
              }
            },
            {
              path: '/surveys/hubs/create',
              component: () => import('@/js/views/surveyhubs/SurveyHubCreate.vue'),
              name: 'surveys.hubs.create',
              meta: {
                rule: 'client_administrator',
                slug: 'surveys.hubs.create',
                acl:'surveys.hubs.create',
                display_name: 'Create a Survey Hub',
                authRequired: true,
                visible: true,
                breadcrumbs: [
                  {display_name: 'Home', category: 'analytics'},
                  {display_name: 'Surveys', category: 'surveys'},
                  { display_name: 'Survey Hubs', name: 'surveys.hubs.index' },
                ],
              }
            },
          ]

				},
				{
					path: '/surveys/hubs/:survey_hub/edit',
					component: () => import('@/js/views/surveyhubs/SurveyHubEdit.vue'),
					name: 'surveys.hubs.edit',
					meta: {
						rule: 'client_administrator',
						slug: 'surveys.hubs.edit',
						acl: 'surveys.hubs.edit',
						display_name: 'Edit a Survey Hub',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Surveys', category: 'surveys' },
							{ display_name: 'Survey Hubs', name: 'surveys.hubs.index' },
						],
					}
				},
			]
		},
		{
			path: '/analytics',
			component: { render: h => h('router-view') },
			meta: {
				display_name: 'Analytics',
				rule: 'client_user',
				acl: ['analytics.events.accountreporting', 'events.show'],
				slug: 'analytics',
				authRequired: true,
				visible: true,
				icon: 'clipboard-data',
				open: true
			},
			children: [
				// Analytics
				{
					path: '/analytics/idle-time',
					component: () => import('@/js/views/analytics/IdleTime.vue'),
					name: 'idle.time',
					meta: {
						rule: 'admin',
						display_name: 'Idle Time',
						acl: ['allow_all'],
						slug: 'idle-time',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				},
				{
					path: '/analytics/interim-reports',
					component: () => import('@/js/views/analytics/InterimReports.vue'),
					name: 'interim.reports',
					meta: {
						rule: 'super_admin',
						display_name: 'Interim Reports',
						acl: ['allow_all'],
						slug: 'interim-reports',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				},
				{
					path: '/analytics/report-explorer/:event_id?',
					component: () => import('@/js/views/analytics/ReportExplorer.vue'),
					name: 'report.explorer',
					meta: {
						rule: 'staff',
						display_name: 'Report Explorer',
						acl: ['allow_all'],
						slug: 'report-explorer',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				},
				{
					path: '/analytics/report-dashboard/:event_id?/:opt?',
					component: () => import('@/js/views/analytics/ReportDashboard.vue'),
					name: 'report.dashboard',
					meta: {
						rule: 'client_user',
						display_name: 'Report Dashboard',
						acl: ['allow_all'],
						slug: 'report-dashboard',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				},
				{
					path: '/analytics/report-comparison',
					component: () => import('@/js/views/analytics/ReportComparison.vue'),
					name: 'report.comparison',
					meta: {
						rule: 'client_user',
						display_name: 'Report Comparison',
						acl: ['allow_all'],
						slug: 'report-comparison',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				},
				{
					path: '/analytics/benchmark/admin',
					component: () => import('@/js/views/analytics/benchmark/BenchmarkAdmin.vue'),
					name: 'benchmark.admin',
					meta: {
						rule: 'super_admin',
						display_name: 'Benchmark Admin',
						acl: ['allow_all'],
						slug: 'benchmark-admin',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				},
				{
					path: '/analytics/report-config/:type_id',
					component: () => import('@/js/views/analytics/ReportConfig.vue'),
					name: 'report.config',
					meta: {
						rule: 'admin',
						display_name: 'Report Config',
						acl: ['allow_all'],
						slug: 'report-config',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'analytics' },
							{ display_name: 'Analytics', category: 'analytics' },
						],
					},
				}
			]
		},
		{
			path: '/events',
			component: { render: h => h('router-view') },
			meta: {
				display_name: 'Events',
				rule: 'client_user',
				acl: ['events.show'],
				slug: 'events',
				authRequired: true,
				visible: true,
				icon: 'calendar3',
				open: false
			},
			children: [
				// Events
				{
					path: '/events/list:page?',
					component: () => import('@/js/views/events/EventList.vue'),
					name: 'events.index',
					meta: {
						rule: 'client_user',
						acl: ['events.show'],
						slug: 'events.index',
						display_name: 'All Events',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'events' },
							{ display_name: 'Events', category: 'events' },
						],
					},
				},
				{
					path: '/events/notifications:page?',
					component: () => import('@/js/views/events/EventNotifications.vue'),
					name: 'notifications.list',
					meta: {
						rule: 'admin',
						acl: ['events.edit'],
						slug: 'notifications.index',
						display_name: 'Notifications',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'events' },
							{ display_name: 'Events', category: 'events' },
						],
					},
				},
                {
                    path: '/events/:event_id/extras',
                    component: () => import('@/js/views/events/EventExtras.vue'),
                    name: 'events.extras',
                    meta: {
                        rule: 'admin',
                        display_name: 'Event Extras',
                        acl: ['events.edit'],
                        slug: 'event-extras',
                        authRequired: true,
                        visible: false,
                        breadcrumbs: [
                            {display_name: 'Home', category: 'events'},
                            {display_name: 'Events', category: 'events'},
                            {display_name: 'List', name: 'events.index'},
                        ],
                    },
                },
				{
					path: '/events/import',
					component: () => import('@/js/views/events/EventImport.vue'),
					name: 'events.import',
					meta: {
						rule: 'client_user',
						acl: ['events.create'],
						slug: 'events.import',
						display_name: 'Bulk Import',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'events' },
							{ display_name: 'Events', category: 'events' },
						],
					},
				},
			]
		},
		{
			path: '/sensors',
			component: { render: h => h('router-view') },
			meta: {
				display_name: 'Sensors',
				rule: 'client_user',
				acl: ['sensors.show'],
				slug: 'sensors',
				authRequired: true,
				visible: true,
				icon: 'wifi',
				open: false
			},
			children: [
				// Events
				{
					path: '/sensors/list:page?',
					component: () => import('@/js/views/sensors/SensorList.vue'),
					name: 'sensors.list',
					meta: {
						rule: 'client_user',
						acl: ['sensors.show'],
						slug: 'sensors.index',
						display_name: 'All Sensors',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'sensors' },
							{ display_name: 'Sensors', category: 'sensors' },
						],
					},
				},
			]
		},
		{
			path: '/users',
			component: { render: h => h('router-view') },
			meta: {
				display_name: 'Users',
				rule: 'client_user',
				acl: ['users.show', 'users.create', 'users.edit', 'users.show'],
				slug: 'users',
				authRequired: true,
				visible: true,
				open: false,
				icon: 'person'
			},
			children: [
				{
					path: '/users/list:page?',
					component: () => import('@/js/views/users/Users.vue'),
					name: 'users.index',
					meta: {
						display_name: 'All Users',
						rule: 'client_user',
						acl: 'users.show',
						slug: 'users.index',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'users' },
							{ display_name: 'Users', category: 'users' },
						],
					}
				},
				{
					path: '/users/create',
					component: () => import('@/js/views/users/UserEdit.vue'),
					name: 'users.create',
					meta: {
						rule: 'client_administrator',
						slug: 'users.create',
						acl: 'users.create',
						display_name: 'Create a User',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'users' },
							{ display_name: 'All Users', name: 'users.index' },
						],
					}
				},
				{
					path: '/users/:user/edit',
					component: () => import('@/js/views/users/UserEdit.vue'),
					name: 'users.edit',
					meta: {
                        rule: 'client_administrator',
						slug: 'users.edit',
						acl: 'users.edit',
						display_name: 'Edit a User',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'users' },
							{ display_name: 'All Users', name: 'users.index' },
						],
					}
				},
				{
					path: '/users/profile',
					component: () => import('@/js/views/users/UserEdit.vue'),
					name: 'users.profile',
					meta: {
						rule: 'client_user',
						slug: 'users.profile',
						acl: 'users.show',
						display_name: 'User Profile',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'users' },
							{ display_name: 'All Users', name: 'users.index' },
						],
					}
				},
				{
					path: '/users/activity',
					component: () => import('@/js/views/users/UserActivity.vue'),
					name: 'user.activity',
					meta: {
						rule: 'admin',
						display_name: 'Activity',
						acl: 'users.show',
						slug: 'user-activity',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'users' },
							{ display_name: 'Users', category: 'users' },
						],
					},
				},
			]
		},
		{
			path: '/accounts',
			component: { render: h => h('router-view') },
			meta: {
				display_name: 'Accounts',
				rule: 'admin',
				acl: ['accounts.show'],
				slug: 'accounts',
				authRequired: true,
				visible: true,
				open: false,
				icon: 'people'
			},
			children: [
				{
					path: '/accounts/list:page?',
					component: () => import('@/js/views/accounts/Accounts.vue'),
					name: 'accounts.index',
					meta: {
						display_name: 'All Accounts',
						rule: 'admin',
						acl: 'accounts.show',
						slug: 'accounts.index',
						authRequired: true,
						visible: true,
						breadcrumbs: [
							{ display_name: 'Home', category: 'accounts' },
							{ display_name: 'Accounts', category: 'accounts' },
						],
					}
				},
				{
					path: '/accounts/create',
					component: () => import('@/js/views/accounts/AccountEdit.vue'),
					name: 'accounts.create',
					meta: {
						rule: 'admin',
						slug: 'accounts.create',
						acl: 'accounts.create',
						display_name: 'Create account',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'accounts' },
							{ display_name: 'All Accounts', name: 'accounts.index' },
						],
					}
				},
				{
					path: '/accounts/:account/edit',
					component: () => import('@/js/views/accounts/AccountEdit.vue'),
					name: 'accounts.edit',
					meta: {
						rule: 'admin',
						slug: 'accounts.edit',
						acl: 'accounts.edit',
						display_name: 'Edit account',
						authRequired: true,
						visible: false,
						breadcrumbs: [
							{ display_name: 'Home', category: 'accounts' },
							{ display_name: 'All Accounts', name: 'accounts.index' },
						],
					}
				},
			]
		},
		{
			path: '/connectionfailed',
			component: () => import('@/js/views/ConnectionFailed.vue'),
			name: 'ConnectionFailed',
			meta: {
				display_name: 'Connection Failed',
				slug: 'connectionfailed',
				rule: 'client_administrator',
				authRequired: true,
				visible: false
			}
		},
		{
			path: '/page-not-found',
			component: () => import('@/js/views/PageNotFound.vue'),
			name: 'PageNotFound',
			meta: {
				display_name: 'Page Not Found',
				slug: 'page.not.found',
				rule: 'user',
				authRequired: true,
				visible: false
			}
		},
		/*
		{
		  path: '/notifications', component: Notifications, name: 'Notifications',
		  meta: {
			rule: 'client_administrator',
			authRequired: true,
			visible: false
		  }
		},
		 */
	]

});


router.beforeEach((to, from, next) => {

	// If auth required, check login. If login fails redirect to login page
	if (to.meta.authRequired) {
		if (!store.getters['auth/isAuthenticated']) {
			store.dispatch('auth/bounceUser', { path: to.path })
		}
	}

	if (to.meta.acl) {
		if (!store.getters['auth/hasScope'](to.meta.acl)) {
			store.dispatch('auth/bounceUser', { path: to.path })
		}
	}

	return next()

})


export default router


import axios from '@/js/axios.js'
import store from '@/js/store/store'
import router from "@/js/router";
import Cookie from "js-cookie";

export default {
  init (vm) {

    //axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    /*
    if(process.env.NODE_ENV == 'production') {
      let token = document.head.querySelector('meta[name="csrf-token"]');
      axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    }
    */

    if(store.state.auth.accessToken) store.commit('auth/SET_BEARER', store.state.auth.accessToken)

    axios.interceptors.request.use(req => {
      if (
        store.state.auth.tokenExpiry
        && !req.url.startsWith('/auth/')
        && !req.url.startsWith('/saml/')
      ) {
        let re = /^([0-9]+)-([0-9]+)-([0-9]+)[ T]([0-9]+):([0-9]+):([0-9]{2})/
        let match = store.state.auth.tokenExpiry.match(re)
        let expiry = Date.UTC(match[1], match[2] -1, match[3], match[4], match[5], match[6])
        let now = Date.now()
        if (expiry < now) {
          store.commit('clearState')
          localStorage.removeItem('analyticsEventList')
          router.push({name: "login"}).catch(() => {});
          return
        }
        let v = Cookie.get('cloudKey').split('|')[1]
        if (expiry - now < 86400 * (v == '1' ? 7 : 0.5) * 1000) {
          store.state.auth.tokenExpiry = null
          store.dispatch('auth/fetchAccessToken')
        }
      }
      vm.$root.$emit("axios_request");
      return req;
    });

    axios.interceptors.response.use((response) => {

      vm.$root.$emit("axios_request_complete");
      return response
    }, (error) => {

      vm.$root.$emit("axios_request_complete");

      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (error.response.status === 401) {
            store.commit('clearState')
            localStorage.removeItem('analyticsEventList')
            router.push({name: "login"}).catch(() => {});
            return Promise.reject(error)
        } else {
            let message = error?.response?.data?.message ?? "You are not authorised to access this content"
            vm.$root.$emit("userFeedback", {success:false, message: message});
        }
      }

      return Promise.reject(error)
    })
  },
  login (payload) {
    return axios.post('/auth/login', payload)
  },
  logout () {
    return axios.get('/auth/logout')
  },
  registerUser (name, email, pwd) {
    return axios.post('/auth/register', {
      displayName: name,
      email,
      password: pwd
    })
  },
  refreshToken () {
    let v = Cookie.get('cloudKey').split('|')[1]
    return axios.post(
        '/auth/refresh-token',
        {
            accessToken: store.state.auth.accessToken,
            short_expiry: (v == '0')
        }
    )
  }
}

import passport from '../../http/requests/auth/passport/index.js'
import mixpanel from '@/mixpanel.js';

import router from '@/js/router'
import store from "@/js/store/store";
import axios from "@/js/axios";

export default {

    bounceUser({commit}, payload) {
        store._vm.$root.$emit("userFeedback", {
            success: false,
            message: "You do not have permission to access this content"
        });
        let route = {name: 'login', params: {to: payload.path}}
        router.push(route).catch(() => {})
    },
    loginSaml({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/auth/login/saml',
                method: 'POST',
                data: payload
            })
            .then((response) => {
                // Track the login attempt
                mixpanel.track('Login attempt with SAML', { email: payload.email });
                mixpanel.people.set({
                    '$email': payload.email,
                });
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    samlCallback({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/saml/${payload.account}/callback`,
                method: 'POST',
                data: payload,
            })
            .then((response) => {
                if (response.data.userData) {
                    const userData = response.data.userData;

                    // Commit user data
                    commit('SET_ACCESS_TOKEN', { token: response.data.accessToken, expiry: response.data.expires_at });
                    commit('UPDATE_USER_INFO', userData);
                    commit('SET_BEARER', response.data.accessToken);
                    mixpanel.identify(userData.name);
                    mixpanel.people.set({
                        '$email': payload.email,
                        '$name': userData.name,
                        '$role': userData.role
                    });
                    mixpanel.track('Login with SAML Callback', { username: userData.username, email: userData.email });

                    resolve(response);
                } else {
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
        });
    },
    // Passport
    loginPassport({commit}, payload) {
        return new Promise((resolve, reject) => {
            passport.login(payload)
            .then(response => {
                if (response.data.userData) {
                    const userData = response.data.userData;

                    // Commit user data
                    commit('SET_ACCESS_TOKEN', { token: response.data.accessToken, expiry: response.data.expires_at });
                    commit('UPDATE_USER_INFO', userData);
                    commit('SET_BEARER', response.data.accessToken);

                    mixpanel.identify(userData.name);
                    mixpanel.people.set({
                        '$name': userData.name,
                        '$role': userData.role
                    });
                    mixpanel.track('Login with Passport', { username: userData.username, email: userData.email });
                    resolve(response);
                } else {
                    resolve(response);
                }
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    logoutPassport({commit}, payload) {
        return new Promise((resolve, reject) => {
            passport.logout()
            .then(response => {
                // Update user details
                commit('DELETE_USER')
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    registerUserPassport({commit}, payload) {

        const {displayName, email, password, confirmPassword} = payload.userDetails

        return new Promise((resolve, reject) => {

            // Check confirm password
            if (password !== confirmPassword) {
                reject({message: 'Password doesn\'t match. Please try again.'})
            }

            passport.registerUser(displayName, email, password)
            .then(response => {
                // Redirect User
                router.push(router.currentRoute.query.to || '/')

                // Set bearer token in axios
                commit('SET_BEARER', response.data.accessToken)
                commit('UPDATE_USER_INFO', response.data.userData)

                mixpanel.track('User Registration', { username: response.data.userData.username, email: response.data.userData.email });
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    fetchAccessToken({commit}) {
        return new Promise((resolve) => {
            passport.refreshToken().then(response => {
                if (response.data.accessToken) {
                    commit('SET_ACCESS_TOKEN', {token: response.data.accessToken, expiry: response.data.expires_at});
                    // Set bearer token in axios
                    commit('SET_BEARER', response.data.accessToken)
                }
                resolve(response)
            })
        })
    },
    forgotPassword({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/auth/forgot',
                method: 'POST',
                data: payload
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    checkResetToken({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/auth/validate',
                method: 'POST',
                data: payload
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    resetPassword({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/auth/reset',
                method: 'POST',
                data: payload
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    changePassword({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: '/users/change-password',
                method: 'POST',
                data: payload
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    },
    adminChangePassword({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({
                url: `/users/${payload.user_id}/change-password`,
                method: 'POST',
                data: payload
            }).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}
import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN, {track_pageview: true});

let mp_active = process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN != undefined
let actions = {
    identify: (id) => {
        if (mp_active) mixpanel.identify(id);
    },
    alias: (id) => {
        if (mp_active) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (mp_active) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (mp_active) mixpanel.people.set(props);
        },
    },
};

export default actions;

import axios from '@/js/axios.js'

export default {
  index({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/users', payload)
        .then((response) => {
          commit('SET_USERS', response.data.users)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  show({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${payload.survey_id}/show`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/create`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  store({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/users/store`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  edit({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${payload.user_id}/edit`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/users/${payload.user_id}/update`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /*deactivate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/users/${payload.user_id}/deactivate`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },*/
  delete({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/users/${payload.user_id}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activity({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/users/activity`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activitySummary({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/activity`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activityDetail({commit}, user_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/activity/` + user_id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App.vue'

// axios
import axios from '@/js/axios.js'
Vue.prototype.$http = axios

// API Calls
import '@/js/http/requests'

// Vue Router
import router from './js/router'

// Access Control
import acl from './js/acl'

// Vuex Store
import store from './js/store/store'

// i18n
import i18n from './js/i18n/i18n'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/app.scss'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VuePageTransition from 'vue-page-transition'
import bFormSlider from 'vue-bootstrap-slider';
import VueMobileDetection from "vue-mobile-detection";

// VeeValidate
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);

// idle view
import IdleVue from "idle-vue"
const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  //idleTime: 1800 * 1000, // 30 minutes
  idleTime: 86400 * 7 * 1000, // a week - effectively disabled
  startAtIdle: false
})

import Slider from './js/components/FormSlider';
import ThresholdModal from "./js/components/threshold/ThresholdModal"
import ThresholdLineChart from './js/components/threshold/ThresholdLineChart';
import MenuItem from '@/js/components/sidenav/MenuItem';

Vue.config.productionTip = false;
/**
 * Uncomment below when compiling to production
 */

if(process.env.NODE_ENV == 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.use(VuePageTransition)
Vue.use(bFormSlider)
Vue.use(VueMobileDetection)



// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('ThresholdModal', ThresholdModal);
Vue.component('ThresholdChart', ThresholdLineChart);
Vue.component('BSlider', Slider);
Vue.component('MenuItem', MenuItem);

window.addEventListener('load', init);
window.addEventListener('resize', resize, false);



function init(){
  new Vue({
    router,
    acl,
    render: h => h(App),
    store,
    i18n
  }).$mount('#app')

  resize();
}

function resize(){

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

}

import axios from '@/js/axios.js'

export default {
  index ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/events/', payload)
          .then((response) => {
              response.data.events.data.forEach(event => event.show = true);
              commit('SET_EVENTS', response.data.events)
              resolve(response)
          })
          .catch((error) => { reject(error) })
    })
  },
  show({commit}, payload) {
    return new Promise((resolve, reject) => {
      var url = `/events/${payload.event_id}`
      if (payload.platformData) {
        url = url + '/1'
      }
      axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  accounts({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/spatial/reporting/accounts', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  location({commit}, location_id) {
    return new Promise((resolve, reject) => {
      var url = '/events/location'
      if (location_id) {
        url = url + '/' + location_id
      }
      axios.get(url)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  save({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/update`, {event_data: payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveNotifications({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/notifications`, {event_data: payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cleanup({commit}, event_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/events/${event_id}/cleanup`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveExtras({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/${payload.event}/extras`, payload.extras)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sensors({commit}, payload) {
    return new Promise((resolve, reject) => {
      payload.sortDesc = payload.sortDesc ? 1 : 0
      payload.selected = payload.selected ? 1 : 0
      axios.get(
        `/sensors/?event=${payload.event}`
        + `&search=${payload.search}`
        + `&sort=${payload.sort}`
        + `&sortDesc=${payload.sortDesc}`
        + `&selected=${payload.selected}`
        + `&results_per_page=${payload.results_per_page}`
        + `&page=${payload.page}`
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sensor_ranges({commit}, sensor_id) {
    return new Promise((resolve, reject) => {
      axios.get(`/sensors/${sensor_id}/ranges`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sensor_update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/${payload.event}/sensor/${payload.sensor.id}`, payload.sensor)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sensor_remove({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/events/${payload.event}/sensor/${payload.sensor}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  import({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/import`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  adminNotifications({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/admin/notifications`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  adminUpdateNotifications({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/admin/notifications/update`, {update: payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  adminDeleteNotifications({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/events/admin/notifications/delete`, {delete: payload})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
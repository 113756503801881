import axios from '@/js/axios.js'

export default {
  accounts({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/spatial/reporting/accounts', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  events({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/spatial/benchmarking/', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/spatial/benchmarking/update', payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
export default {
  isAuthenticated: (state) => {
    return (state.user)? true : false;
  },
  hasScope: (state) => (scope_name) => {
    let allow = true;
    if(state.user && scope_name != 'allow_all') {
      allow = false
      if (Array.isArray(scope_name)) {
        scope_name.forEach((item) => {
          if(state.user.scopes.indexOf(item) != -1) allow = true;
        });
      } else {
        if(state.user.scopes.indexOf(scope_name) != -1) allow = true;
      }
    }
    return allow;
  },
  isStaffMember: (state) => {
    return (state.user && state.user.role == "admin")? true : false;
  }
}

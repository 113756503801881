import axios from '@/js/axios.js'

export default {
  index({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/${payload.slug}/responses`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  dedupe({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/${payload.slug}/responses/dedupe`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkprofanity({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/${payload.slug}/responses/checkprofanity`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  emaildownloadlink({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/${payload.slug}/responses/emaildownloadlink`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  download({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/${payload.slug}/responses/download`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  edit({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveyresponses/${payload.response_id}/edit`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveyresponses/${payload.response_id}/update`, payload.form_data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/surveys/responses/${payload.response_id}/delete`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
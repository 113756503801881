import axios from '@/js/axios.js'

export default {
  fetch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/threshold/fetch', payload)
        .then((response) => {
          payload.data = response.data.threshold;
          commit('CACHE_THRESHOLD_DATA', payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
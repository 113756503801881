export default {
  SET_EVENT_LIST(state, eventList){
    state.event_list = eventList
  },
  SET_LAST_EVENT(state, event) {
    state.last_event = event
  },
  CLEAR_STATE(state) {
    state.last_event = null
    state.event_list = null
  }
}
import axios from '@/js/axios.js'

export default {
  index({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/surveys', payload)
        .then((response) => {
          commit('SET_SURVEYS', response.data.surveys)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveys/create`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  show({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveys/${payload.survey_id}/show`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  edit({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveys/${payload.survey_id}/edit`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  docreate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/create`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/surveys/${payload.survey_id}/update`, payload.form_data)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/surveys/${payload.survey_id}/delete`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  requestpush({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveys/${payload.survey_id}/requestpush`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  confirmpush({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/surveys/${payload.survey_id}/confirmpush`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
<template>
  <div>
    <b-form-slider
      ref="notificationSlider"
      :min="0"
      :max="60"
      :value="value"
      scale="logarithmic"
      @slide-start="slideStart"
      @slide-stop="slideStop"
    />
    <p class="mt-3">
      Every {{ value }} minutes
    </p>
  </div>
</template>

<script>
    export default {
        data () {
            return {
                value: this.$store.state.notification_frequency / 60000,
                sliderRef:null
            }
        },
        methods: {
            slideStart () {
                let sliderRef = this.$refs.notificationSlider;
                this.value = sliderRef.slider.getValue();
            },
            slideStop () {
                let sliderRef = this.$refs.notificationSlider;
                this.value = sliderRef.slider.getValue();
                this.$store.commit('updateNotificationFrequency', this.value);
            }
        }
    }
</script>

<style lang="scss">
    .tooltip.in {
        opacity: 1;
    }
</style>
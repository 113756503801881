import axios from '@/js/axios.js'

export default {
  index({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/accounts', payload)
        .then((response) => {
          commit('SET_ACCOUNTS', response.data.accounts)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/accounts/create`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  store({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/accounts/store`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  edit({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`/accounts/${payload.id}/edit`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/accounts/${payload.id}/update`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.delete(`/accounts/${payload.id}/delete`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  checkCertificate({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(
        '/accounts/check-certificate',
        payload,
        {headers: {'Content-Type': 'multipart/form-data'}}
      ).then((response) => {
        resolve(response)
      })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
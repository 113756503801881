import state from './moduleSurveyResponsesState.js'
import mutations from './moduleSurveyResponsesMutations.js'
import actions from './moduleSurveyResponsesActions.js'
import getters from './moduleSurveyResponsesGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

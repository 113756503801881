<template>
  <b-dropdown
    class="zone-drop-down"
    :text="selected_zone.name"
    menu-class="w-100"
  >
    <b-dropdown-item-button
      v-for="(zone, key) in zones"
      :key="key"
      :active="selected_zone.id == zone.id"
      @click="SET_SELECTED_ZONE(zone)"
    >
      {{ zone.name }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>

    import {mapGetters, mapState, mapMutations} from "vuex";

    export default {
        name: "ZonesDropDown",
        data() {
            return {
                selected_zones:[]
            }
        },
        computed: {
            ...mapGetters({
                zoneIndex:'zoneIndex'
            }),
            ...mapState({
                zones:  state => state.zones.zones,
                selected_zone:  state => state.zones.selected_zone,
            }),
        },
        methods: {
          ...mapMutations('zones', ['SET_SELECTED_ZONE']),
        }
    }

</script>

<style>

    .zone-drop-down .dropdown-toggle::after {
        content: none;
    }

</style>
<template>
  <b-card
    v-if="route.meta.visible"
    no-body
    class="border-0 mb-2"
  >
    <b-card-header
      v-if="$acl.check(route.meta.rule)"
      v-can="route.meta.acl"
      header-tag="header"
      class="p-0 border-0"
      role="tab"
    >
      <b-button
        ref="button-surveys"
        v-b-toggle="route.meta.slug"
        variant="primary"
        block
        class="text-left menutoggle w-100"
      >
        {{ route.meta.display_name }}

        <b-icon
          v-if="route.meta.icon"
          font-scale="1.5"
          :icon="route.meta.icon"
          class="m-0 float-right"
        />
      </b-button>
    </b-card-header>
    <b-collapse
      :id="route.meta.slug"
      class="mt-1"
      accordion="main-menu-accordion"
      :visible="route.meta.open"
      @input="stateChange"
    >
      <nav class="mt-1">
        <menu-item
          v-for="(r,i) in route.children"
          :id="`acc-${route.meta.slug}`"
          :key="i"
          role="tabpanel"
          :route="r"
        />
      </nav>
    </b-collapse>
  </b-card>
</template>

<script>

export default {
    name: "AccordionMenu",
    props: {
        route: Object
    },
    methods: {
        stateChange(state) {
            this.$emit("state-change", this.route.meta.slug, state)
        }
    }
}
</script>

<style scoped>

</style>
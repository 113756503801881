<template>
  <div>
    <b-sidebar
      id="sidebar"
      ref="sidebar"
      v-model="visible"
      bg-variant="white"
      width="250px"
      aria-labelledby="sidebar"
      no-header
      shadow
      backdrop
      backdrop-variant="transparent"
      visible
      @change="change"
    >
      <template #default="{ hide }">
        <div class="p-3">
          <img
            class="logo"
            src="@/assets/meshh-ll-logo.svg"
          >

          <div
            role="tablist"
            class="mt-4 mb-5"
          >
            <div
              v-for="(r,i) in $router.options.routes"
              :key="i"
              :route="r"
            >
              <accordion-menu
                v-if="!r.name"
                :key="i"
                :route="r"
                @state-change="accordionChanged"
              />
              <menu-item
                v-else
                :route="r"
              />
            </div>
          </div>

          <b-button @click="hide">
            Close menu
          </b-button>
        </div>
        <b-img
          src="@/assets/meshh-lattice.png"
          class="sidebar-b-image w-100 align-bottom"
        />
      </template>
    </b-sidebar>
  </div>
</template>

<script>

import AccordionMenu from './AccordionMenu';

export default {
    name: "SideNav",
    components: {
        AccordionMenu
    },
    props: {
        request: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            container: document.getElementById("app"),
            visible: true
        }
    },
    methods: {
        accordionChanged(id, state) {
            this.$router.options.routes.forEach(route => {
                if (!route.name && route.meta.slug == id) {
                    route.meta.open = state
                }
            })
        },
        change(visible) {
            //this.visible = visible;
            (visible) ? this.container.classList.add("sidebar-active") : this.container.classList.remove("sidebar-active");
        },
        showCategory(category) {
            this.$router.options.routes.forEach(route => {
                if (!route.name && route.meta.slug == category && !route.meta.open) {
                    this.$root.$emit('bv::toggle::collapse', category)
                }
            })
            this.visible = true
        }
    },
}
</script>

<style lang="scss">

#sidebar {

    .logo {
        width: 160px;
        /*opacity:.4;*/
    }

    .sidebar-b-image {
        position: absolute;
        bottom: 0;
        height: 60%;
        width: 100%;
        object-fit: cover;
        opacity: .15;
        pointer-events: none;
    }


    .b-sidebar-body {
        margin-top: 15px;
    }
}


</style>

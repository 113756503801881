<template>
  <nav
    v-if="$router.currentRoute.path != '/'"
    id="Breadcrumbs"
    class="mb-1 ml-2"
  >
    <b-nav v-if="breadcrumbs.length > 0">
      <template v-for="(r,i) in breadcrumbs">
        <b-nav-item
          v-if="r.name"
          :key="'n_' + i"
          :to="{name:r.name}"
        >
          {{ r.display_name }}<span class="ml-2">></span>
        </b-nav-item>
        <b-nav-item
          v-else
          :key="'c_' + i"
          @click="showCategory(r.category)"
        >
          {{ r.display_name }}<span class="ml-2">></span>
        </b-nav-item>
      </template>
      <b-nav-item>{{ $router.currentRoute.meta.display_name }}</b-nav-item>
    </b-nav>
  </nav>
</template>

<script>
export default {
  name: "BreadCrumbs",
  data() {
    return {
      breadcrumbs: []
    }
  },
  watch: {
    $route(to, from) {
      this.breadcrumbs = (this.$router.currentRoute.meta.breadcrumbs) ? this.$router.currentRoute.meta.breadcrumbs : [];
      //console.dir(this.breadcrumbs);
      this.$forceUpdate();
    }
  },
  methods: {
      showCategory(category) {
          this.$emit('show-category', category)
      }
  }
}
</script>

<style lang="scss" scoped>

#Breadcrumbs {
  .nav-link{
    padding: .1rem .25rem;
  }
}

</style>
import state from './moduleBenchmarkState.js'
import mutations from './moduleBenchmarkMutations.js'
import actions from './moduleBenchmarkActions.js'
import getters from './moduleBenchmarkGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

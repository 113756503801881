<template>
  <div class="text-center">
    <b-icon
      icon="stopwatch"
      font-scale="3"
    />
    <p class="expire-label">
      Your session is expiring...
    </p>
    <p class="expire-label">
      {{ time }}
    </p>
  </div>
</template>

<script>
export default {
    name: "IdleExpire",
    data() {
        return {
            time: 60
        }
    },
    created() {
        let timerId = setInterval(() => {
            this.time -= 1
            if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)
            if (this.time < 1) {
                clearInterval(timerId)
                this.$root.$emit('userLogout')
            }
        }, 1000)
    }
}
</script>

<style lang="scss" scoped>
.text-center {
    position: relative;
    border: 2px solid $meshh_blue;
    background-color: $meshh_dark;
    color: white;
    border-radius: 1rem;
    padding: 1rem;
}
.expire-label {
    margin: 0.5rem;
}
</style>